import React, { useState, useEffect } from 'react';
import { Table, Pagination, Button, TextInput, Tooltip, Popover, Modal } from 'flowbite-react';
import { fetchUsers, updateMaxLeads, resetUserPassword } from '../services/api';
import { FaCheck, FaTimes } from 'react-icons/fa';

const UsersTable = ({ filters, search, onUserSelect }) => {
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [editingUser, setEditingUser] = useState(null);
  const [newMaxLeads, setNewMaxLeads] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  useEffect(() => {
    fetchUsers(currentPage, filters, search).then((data) => {
      setUsers(data.results);
      setPagination({
        count: data.count,
        next: data.next,
        previous: data.previous,
      });
    });
  }, [currentPage, filters, search]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.max(1, Math.ceil((pagination.count || 0) / 10));

  const handleEditMaxLeads = (user, e) => {
    e.stopPropagation();
    setEditingUser(user);
    setNewMaxLeads(user.max_number_of_leads.toString());
  };

  const handleCancelEdit = (e) => {
    e.stopPropagation();
    setEditingUser(null);
    setNewMaxLeads('');
  };

  const handleSaveMaxLeads = async (userId, e) => {
    e.stopPropagation();
    try {
      const updatedUser = await updateMaxLeads(userId, parseInt(newMaxLeads, 10));
      setUsers(users.map(user => user.id === userId ? { ...user, max_number_of_leads: updatedUser.max_number_of_leads } : user));
      setEditingUser(null);
      setNewMaxLeads('');
    } catch (error) {
      console.error('Error updating max leads:', error);
    }
  };

  const handleResetPassword = async (userId, e) => {
    e.stopPropagation();
    try {
      const response = await resetUserPassword(userId);
      setModalMessage(response.detail);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error resetting password:', error);
      setModalMessage('Failed to reset password. Please try again.');
      setIsModalOpen(true);
    }
  };

  const MaxLeadsCell = ({ user }) => {
    if (user.user_type !== 'ballot_chaser') {
      return <span></span>;
    }

    if (editingUser?.id === user.id) {
      return (
        <Popover
          content={
            <div className="p-3">
              <TextInput
                type="number"
                value={newMaxLeads}
                onChange={(e) => setNewMaxLeads(e.target.value)}
                className="mb-2"
                onClick={(e) => e.stopPropagation()}
              />
              <div className="flex justify-end space-x-2">
                <Tooltip content="Save">
                  <Button color="green" size="xs" onClick={(e) => handleSaveMaxLeads(user.id, e)}>
                    <FaCheck />
                  </Button>
                </Tooltip>
                <Tooltip content="Cancel">
                  <Button color="gray" size="xs" onClick={handleCancelEdit}>
                    <FaTimes />
                  </Button>
                </Tooltip>
              </div>
            </div>
          }
          trigger="click"
          open={true}
        >
          <span className="cursor-pointer text-blue-600 hover:underline">{user.max_number_of_leads}</span>
        </Popover>
      );
    }

    return (
      <Tooltip content="Click to edit">
        <span
          className="cursor-pointer text-blue-600 hover:underline"
          onClick={(e) => handleEditMaxLeads(user, e)}
        >
          {user.max_number_of_leads}
        </span>
      </Tooltip>
    );
  };

  return (
    <div>
      <Table hoverable>
        <Table.Head>
          <Table.HeadCell>Username</Table.HeadCell>
          <Table.HeadCell>Email</Table.HeadCell>
          <Table.HeadCell>User Type</Table.HeadCell>
          <Table.HeadCell>State</Table.HeadCell>
          <Table.HeadCell>City</Table.HeadCell>
          <Table.HeadCell>ZIP Code</Table.HeadCell>
          <Table.HeadCell>Phone</Table.HeadCell>
          <Table.HeadCell>Max Leads</Table.HeadCell>
          <Table.HeadCell>Reset Password</Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {users.map((user) => (
            <Table.Row
              key={user.id}
              className="bg-white dark:border-gray-700 dark:bg-gray-800 cursor-pointer hover:bg-gray-50"
              onClick={() => onUserSelect(user)}
            >
              <Table.Cell>{user.username}</Table.Cell>
              <Table.Cell>{user.email}</Table.Cell>
              <Table.Cell>{user.user_type}</Table.Cell>
              <Table.Cell>{user.state}</Table.Cell>
              <Table.Cell>{user.city}</Table.Cell>
              <Table.Cell>{user.zip_code}</Table.Cell>
              <Table.Cell>{user.phone}</Table.Cell>
              <Table.Cell>
                <MaxLeadsCell user={user} />
              </Table.Cell>
              <Table.Cell>
                <Button
                  color="green"
                  size="xs"
                   className="mx-auto"
                  onClick={(e) => handleResetPassword(user.id, e)}
                >
                  Reset
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <div className="flex justify-center mt-4">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
      <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Modal.Header>Password Reset</Modal.Header>
        <Modal.Body>
          <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            {modalMessage}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setIsModalOpen(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UsersTable;