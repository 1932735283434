// src/services/auth.js
import createAxiosInstance from './axiosInstance';

const axiosInstance = createAxiosInstance(() => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    window.location.href = '/login';
});

export const login = async (username, password) => {
    try {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        console.log('Logging in...');
        const response = await axiosInstance.post('/api/token/', {username, password});
        const {access, refresh, terms_accepted} = response.data;
        localStorage.setItem('accessToken', access);
        localStorage.setItem('refreshToken', refresh);
        return {access, refresh, terms_accepted};
    } catch (error) {
        if (error.response && error.response.data && error.response.data.detail) {
            throw new Error(error.response.data.detail);
        }
        throw new Error('Invalid username or password');
    }
};

export const acceptTerms = async () => {
    const response = await axiosInstance.post('/api/users/accept-terms/');
    return response.data;
};

export const refreshToken = async () => {
    const refresh = localStorage.getItem('refreshToken');
    const response = await axiosInstance.post('/api/token/refresh/', {refresh});
    const {access} = response.data;
    localStorage.setItem('accessToken', access);
    return access;
};

export const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    window.location.href = '/login';
};

// Create a named object for the default export
const authService = {
    login,
    acceptTerms,
    refreshToken,
    logout,
};

export default authService;