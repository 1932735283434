import React, {useState, useEffect, useCallback} from 'react';
import {Button, Label, TextInput, Select} from 'flowbite-react';
import {states_dict, citiesByState} from '../constants/locationData';
import {Loader} from "@googlemaps/js-api-loader";

const CreateUserForm = ({onSubmit, currentUserType}) => {
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        user_type: '',
        state: '',
        city: '',
        zip_code: '',
        phone: '',
        address: '',
        lat: null,
        lon: null
    });

    const [cities, setCities] = useState([]);
    const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);

    const initializeGoogleMaps = useCallback(() => {
        if (window.google && window.google.maps && formData.user_type === 'ballot_chaser') {
            const autocomplete = new window.google.maps.places.Autocomplete(
                document.getElementById("address"),
                {types: ["geocode"], componentRestrictions: {country: "us"}} // Addresses, cities, and postal codes for US
            );

            const map = new window.google.maps.Map(document.getElementById("map"), {
                center: {lat: 40, lng: -100},
                zoom: 4
            });

            const marker = new window.google.maps.Marker({
                map: map,
                anchorPoint: new window.google.maps.Point(0, -29)
            });

            autocomplete.addListener("place_changed", () => {
                marker.setVisible(false);
                const place = autocomplete.getPlace();

                if (!place.geometry || !place.geometry.location) {
                    console.log("No details available for input: '" + place.name + "'");
                    return;
                }

                // Set zoom level and marker only for precise locations
                if (place.geometry.viewport) {
                    map.fitBounds(place.geometry.viewport);
                } else {
                    map.setCenter(place.geometry.location);
                    map.setZoom(17);
                }

                marker.setPosition(place.geometry.location);
                marker.setVisible(true);

                let address = '';
                let zipCode = '';
                let state = '';
                let city = '';

                for (const component of place.address_components) {
                    const componentType = component.types[0];

                    switch (componentType) {
                        case "street_number":
                            address = `${component.long_name} ${address}`;
                            break;
                        case "route":
                            address += component.short_name;
                            break;
                        case "postal_code":
                            zipCode = `${component.long_name}${zipCode}`;
                            break;
                        case "locality":
                            city = component.long_name;
                            break;
                        case "administrative_area_level_1": // State
                            state = component.short_name;
                            break;
                        default:
                            break;
                    }
                }

                setFormData(prev => ({
                    ...prev,
                    address: place.formatted_address || address,
                    state: state,
                    city: city,
                    zip_code: zipCode,
                    lat: place.geometry.location.lat(),
                    lon: place.geometry.location.lng()
                }));

                console.log("State:", state);
                console.log("lat:", place.geometry.location.lat());
                console.log("lon:", place.geometry.location.lng());
                console.log("Zip Code:", zipCode);
            });
        }
    }, [formData.user_type]);

    useEffect(() => {
        const loader = new Loader({
            apiKey: "AIzaSyDKT6NsEu25cWSgppoe6ZzsJGxr6t9xbcA",
            version: "weekly",
            libraries: ["places"]
        });

        loader.load().then(() => {
            setGoogleMapsLoaded(true);
        }).catch(e => console.error("Error loading Google Maps API", e));
    }, []);

    useEffect(() => {
        if (googleMapsLoaded) {
            initializeGoogleMaps();
        }
    }, [googleMapsLoaded, initializeGoogleMaps]);

    useEffect(() => {
        if (formData.state && formData.user_type !== 'ballot_chaser') {
            setCities(citiesByState[formData.state] || []);
        } else {
            setCities([]);
        }
    }, [formData.state, formData.user_type]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevData => {
            const newData = {...prevData, [name]: value};

            if (name === 'user_type') {
                newData.state = '';
                newData.city = '';
                newData.zip_code = '';
                newData.address = '';
                newData.lat = null;
                newData.lon = null;
            }

            if (name === 'state' && formData.user_type !== 'ballot_chaser') {
                newData.city = '';
            }

            return newData;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await onSubmit(formData);
            setFormData({
                username: '',
                email: '',
                user_type: '',
                state: '',
                city: '',
                zip_code: '',
                phone: '',
                address: '',
                lat: null,
                lon: null
            });
        } catch (error) {
            // Error is handled in the parent component
        }
    };

    const userTypes = [
        {value: 'admin', label: 'Admin'},
        {value: 'national_director', label: 'National Director'},
        {value: 'state_director', label: 'State Director'},
        {value: 'ballot_chaser', label: 'Ballot Chaser'}
    ];

    const allowedUserTypes = userTypes.filter(type => {
        if (currentUserType === 'admin') return true;
        if (currentUserType === 'national_director') return ['state_director', 'ballot_chaser'].includes(type.value);
        if (currentUserType === 'state_director') return type.value === 'ballot_chaser';
        return false;
    });

    const showStateField = ['state_director', 'ballot_chaser'].includes(formData.user_type);
    const showCityZipFields = formData.user_type === 'ballot_chaser';

    return (
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
            <div>
                <Label htmlFor="username" value="Username"/>
                <TextInput
                    id="username"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    required
                />
            </div>
            <div>
                <Label htmlFor="email" value="Email"/>
                <TextInput
                    id="email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
            </div>
            <div>
                <Label htmlFor="user_type" value="User Type"/>
                <Select
                    id="user_type"
                    name="user_type"
                    value={formData.user_type}
                    onChange={handleChange}
                    required
                >
                    <option value="">Select user type</option>
                    {allowedUserTypes.map(type => (
                        <option key={type.value} value={type.value}>{type.label}</option>
                    ))}
                </Select>
            </div>
            {formData.user_type === 'ballot_chaser' ? (
                <>
                    <div>
                        <Label htmlFor="address" value="Address"/>
                        <TextInput
                            id="address"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div id="map" style={{height: '300px', width: '100%'}}></div>
                </>
            ) : (
                <>
                    {showStateField && (
                        <div>
                            <Label htmlFor="state" value="State"/>
                            <Select
                                id="state"
                                name="state"
                                value={formData.state}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select state</option>
                                {Object.entries(states_dict).map(([stateName, stateAbbr]) => (
                                    <option key={stateAbbr} value={stateAbbr}>{stateName} ({stateAbbr})</option>
                                ))}
                            </Select>
                        </div>
                    )}
                    {showCityZipFields && (
                        <>
                            <div>
                                <Label htmlFor="city" value="City"/>
                                <Select
                                    id="city"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleChange}
                                    required
                                    disabled={!formData.state}
                                >
                                    <option value="">Select city</option>
                                    {cities.map(city => (
                                        <option key={city} value={city}>{city}</option>
                                    ))}
                                </Select>
                            </div>
                            <div>
                                <Label htmlFor="zip_code" value="ZIP Code"/>
                                <TextInput
                                    id="zip_code"
                                    name="zip_code"
                                    value={formData.zip_code}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </>
                    )}
                </>
            )}
            <div>
                <Label htmlFor="phone" value="Phone"/>
                <TextInput
                    id="phone"
                    name="phone"
                    type="tel"
                    value={formData.phone}
                    onChange={handleChange}
                />
            </div>
            <Button type="submit">Create User</Button>
        </form>
    );
};

export default CreateUserForm;