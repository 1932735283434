import React, { useState, useEffect } from 'react';
import { fetchFilterOptions, fetchAutocomplete, assignNearestLeads, fetchUserData } from '../services/api';
import { Select, TextInput, Button, Spinner, Checkbox, Label, Tooltip, Alert } from 'flowbite-react';
import { HiInformationCircle } from 'react-icons/hi';

const FilterForm = ({ onFilterChange, setResetCheckboxes, userType, openAssignNearestModal }) => {
    const [filterType, setFilterType] = useState('location');
    const [filters, setFilters] = useState({
        state: '',
        city: '',
        zip_code: '',
        assigned_to: '',
        search: '',
        status: 'all', // New status filter
    });
    const [options, setOptions] = useState({
        states: [],
        cities: [],
        zip_codes: [],
        ballot_chasers: [],
    });
    const [loading, setLoading] = useState(false);
    const [noAssignedChaser, setNoAssignedChaser] = useState(false);
    const [isAssigningLeads, setIsAssigningLeads] = useState(false);
    const [assignError, setAssignError] = useState(null);
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const fetchOptions = async () => {
            setLoading(true);
            try {
                const newOptions = await fetchFilterOptions(filterType);
                setOptions(prevOptions => ({
                    ...prevOptions,
                    ...newOptions
                }));
            } catch (error) {
                console.error('Error fetching filter options:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchOptions();
    }, [filterType]);

    useEffect(() => {
        const getUserData = async () => {
            const data = await fetchUserData();
            setUserData(data);
        };

        getUserData();
    }, []);

    const handleFilterTypeChange = (e) => {
        if (userType !== 'ballot_chaser') {
            setFilterType(e.target.value);
            setFilters({
                state: '',
                city: '',
                zip_code: '',
                assigned_to: '',
                search: '',
                status: 'all',
            });
            setNoAssignedChaser(false);
            onFilterChange({}, true);
        }
    };

    const handleChange = async (e) => {
        const { name, value } = e.target;
        setFilters(prev => ({ ...prev, [name]: value }));

        if (name !== 'search' && name !== 'assigned_to' && name !== 'zip_code' && name !== 'status' && value.length > 1) {
            const results = await fetchAutocomplete(name, value);
            setOptions(prev => ({ ...prev, [name]: results }));
        }
    };

    const handleNoAssignedChaserChange = (e) => {
        setNoAssignedChaser(e.target.checked);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const submittedFilters = {...filters};
        if (filterType === 'location' && noAssignedChaser && userType !== 'ballot_chaser') {
            submittedFilters.assigned_to = 'none';
        }
        onFilterChange(submittedFilters);
    };

    const resetFilters = () => {
        setFilters({
            state: '',
            city: '',
            zip_code: '',
            assigned_to: '',
            search: '',
            status: 'all',
        });
        setNoAssignedChaser(false);
        onFilterChange({}, true);
    };

    const handleAssignNearestLeads = async () => {
        if (userType === 'ballot_chaser') {
            setIsAssigningLeads(true);
            setAssignError(null);
            try {
                const result = await assignNearestLeads();
                console.log(result.message);
                onFilterChange({});
            } catch (error) {
                console.error('Error assigning nearest leads:', error);
                if (error.response && error.response.data) {
                    setAssignError(error.response.data);
                } else {
                    setAssignError({ error: 'An unexpected error occurred. Please try again.' });
                }
            } finally {
                setIsAssigningLeads(false);
            }
        } else {
            openAssignNearestModal();
        }
    };

    const dismissAlert = () => {
        setAssignError(null);
    };

    const handleRequestMoreLeads = () => {
        if (userData) {
            const subject = encodeURIComponent("Request for More Leads");
            const body = encodeURIComponent(`Ballot Chaser ${userData.username} in State ${userData.state} is requesting more leads.`);
            window.location.href = `mailto:noreen@cforc.com?subject=${subject}&body=${body}`;
        }
    };

    return (
        <form onSubmit={handleSubmit} className="mb-4">
            {userType !== 'ballot_chaser' && (
                <div className="mb-4">
                    <Select value={filterType} onChange={handleFilterTypeChange}>
                        <option value="location">Filter by Location</option>
                        <option value="ballot_chaser">Filter by Ballot Chaser</option>
                    </Select>
                </div>
            )}
            <div className="mb-4 mt-4">
                <Select name="status" value={filters.status} onChange={handleChange}>
                    <option value="all">All Statuses</option>
                    <option value="completed">Completed</option>
                    <option value="not_completed">Not Completed</option>
                </Select>
            </div>
            <div className="mb-4">
                <TextInput
                    type="text"
                    name="search"
                    value={filters.search}
                    onChange={handleChange}
                    placeholder="Search leads by name, city, zip code, or address"
                />
            </div>

            {loading ? (
                <div className="flex justify-center">
                    <Spinner/>
                </div>
            ) : filterType === 'location' || userType === 'ballot_chaser' ? (
                <>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <Select name="state" value={filters.state} onChange={handleChange}>
                            <option value="">Select State</option>
                            {options.states && options.states.map((state) => (
                                <option key={state} value={state}>{state}</option>
                            ))}
                        </Select>
                        <Select name="city" value={filters.city} onChange={handleChange}>
                            <option value="">Select City</option>
                            {options.cities && options.cities.map((city) => (
                                <option key={city} value={city}>{city}</option>
                            ))}
                        </Select>
                        <TextInput
                            type="text"
                            name="zip_code"
                            value={filters.zip_code}
                            onChange={handleChange}
                            placeholder="Enter ZIP Code"
                        />
                    </div>
                    {userType !== 'ballot_chaser' && (
                        <div className="flex items-center gap-2 mt-4">
                            <Checkbox
                                id="noAssignedChaser"
                                checked={noAssignedChaser}
                                onChange={handleNoAssignedChaserChange}
                            />
                            <Label htmlFor="noAssignedChaser">
                                Show only leads with no assigned ballot chaser
                            </Label>
                        </div>
                    )}
                </>
            ) : (
                <Select name="assigned_to" value={filters.assigned_to} onChange={handleChange}>
                    <option value="">All Ballot Chasers</option>
                    {options.ballot_chasers && options.ballot_chasers.map((chaser) => (
                        <option key={chaser.id} value={chaser.username}>{chaser.username}</option>
                    ))}
                </Select>
            )}


            <div className="flex flex-col sm:flex-row justify-between items-center mt-4 space-y-2 sm:space-y-0">
                <Button type="submit" className="w-full sm:w-auto">Apply Filters</Button>
                <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 w-full sm:w-auto">
                    <Tooltip
                        content={userType === 'ballot_chaser' ? "Assign up to 20 nearest leads if eligible" : "Assign nearest leads to a ballot chaser"}>
                        <Button
                            type="button"
                            onClick={handleAssignNearestLeads}
                            disabled={userType === 'ballot_chaser' && isAssigningLeads}
                            className="w-full sm:w-auto"
                        >
                            {userType === 'ballot_chaser' && isAssigningLeads ? 'Assigning...' : 'Assign Nearest Leads'}
                        </Button>
                    </Tooltip>
                    <Button type="button" onClick={resetFilters} color="gray" className="w-full sm:w-auto">
                        Reset Filters
                    </Button>
                </div>
            </div>

            {assignError && userType === 'ballot_chaser' && (
                <Alert
                    color="failure"
                    icon={HiInformationCircle}
                    onDismiss={dismissAlert}
                    className="mt-4"
                >
                    <h3 className="font-medium">Unable to assign leads</h3>
                    <p>{assignError.error}</p>
                    {assignError.leads_to_complete > 0 && (
                        <p>
                            You need to complete {assignError.leads_to_complete} more lead(s) before you can be assigned
                            new ones.
                            Current status: {assignError.completed_leads} completed out
                            of {assignError.total_assigned} assigned.
                        </p>
                    )}
                    {assignError.reach_max_leads && (
                        <div className="mt-2">
                            <p>You have reached the maximum number of leads. Please contact an Admin, a National
                                Director, or your State Director to request an increase in your max number of leads.</p>
                            <Button
                                onClick={handleRequestMoreLeads}
                                className="mt-2"
                            >
                                Request More Leads
                            </Button>
                        </div>
                    )}
                </Alert>
            )}
        </form>
    );
};

export default FilterForm;